import * as actionTypes from "../action-types";

const initialState = {
  mailData: []
};

const mailReducer = (state = initialState, actions) => {
  const { payload, type } = actions;

  switch (type) {
    case actionTypes.SET_MAIL_LIST: {
      return {
        ...state,
        mailData: payload,
      };
    }

    

    default: {
      return state;
    }
  }
};

export default mailReducer;
