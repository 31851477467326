import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { secondsToHMS } from "../../../../utils/common-function/index.js";
import MainDatatable from "../../../../components/common/MainDatatable.jsx";
import InvoiceOne from "../../../history/download-invoice/invoice-one/index.js";
import * as CustomerActions from '../../../../redux/actions/customerAction.js';
import {OnlyTime} from "../../../../utils/common-function/index.js";

const PujaHistory = ({ customerId }) => {
    const dispatch = useDispatch();
    const { pujaHistoryByCustomerIdData } = useSelector(state => state?.customerReducer);

    console.log(pujaHistoryByCustomerIdData, "@@@@@@@@@@@@@@@@@@&&&&&&&&&&&&")

    //* Data-Table Column
    const columns = [
        { name: 'S.No.', selector: (row) => pujaHistoryByCustomerIdData.indexOf(row) + 1, width: '80px' },
        { name: 'Puja', selector: row => row?.poojaId ? row?.poojaId?.pujaName : 'N/A' },
        { name: 'Price', selector: row => row?.poojaId ? row?.poojaId.price  : 'N/A' },
        { name: 'Astrologer', selector: row => row?.astrologer?.astrologerName ? row?.astrologer?.astrologerName : 'N/A' },
        { name: 'Start Date', selector: row => row?.pujaDate ? moment(row?.pujaDate).format('DD MMM YYYY') : 'N/A' },
        { name: 'Start Time', selector: row => row?.pujaTime ? OnlyTime(row?.pujaTime) : 'N/A' }, 
        // { name: 'Date', selector: row => row?.endTime ? moment(row?.createdAt).format('DD MMMM YYYY') : 'N/A', width: "180px" },
        // { name: 'Invoice', cell: row => <InvoiceOne data={row} type={'live'} /> }
    ];

    useEffect(function () {
        //! Dispatching API for Getting Pooja History
        dispatch(CustomerActions.getPujaHistoryByCustomerId({ customerId }));
    }, []);

    return (
        <>
            <MainDatatable data={pujaHistoryByCustomerIdData} columns={columns} title={'Puja History'} />

        </>
    )
};

export default PujaHistory;