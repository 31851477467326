import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainDatatable from "../../components/common/MainDatatable.jsx";
import * as MailActions from '../../redux/actions/mailActions.js'
import { Avatar, Dialog, DialogContent, Divider, Grid, Typography } from '@mui/material';
import { Color } from "../../assets/colors/index.js";
import { CrossSvg, ViewSvg } from "../../assets/svg/index.js";

const MailList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mailData } = useSelector(state => state?.mailReducer);
    const [mailModal, setMailModal] = useState({ isOpen: false, data: [] });

    console.log('Mail data ::: ', mailModal);

    //* DataTable Columns
    const columns = [
        { name: 'S.No.', selector: row => mailData.indexOf(row) + 1, width: '80px' },
        { name: 'Name', selector: row => row?.name ? row?.name : 'N/A' },
        { name: 'Email', selector: row => row?.email ? row?.email : 'N/A' },
        { name: 'Subject', selector: row => row?.subject ? row?.subject : 'N/A' },
        { name: 'Message', selector: row => row?.message ? row?.message : 'N/A' },
        {
            name: 'Action',
            cell: row => <div style={{ display: "flex", gap: "20px", alignItems: "center" }} >
                <div onClick={() => setMailModal({ isOpen: true, data: row })} style={{ cursor: "pointer" }}><ViewSvg /></div>
                {/* <div onClick={() => dispatch(PagesActions.deleteAnnouncement(row._id))} style={{ cursor: "pointer" }}><DeleteSvg /></div> */}
            </div >,
            width: "150px", centre: true
        },
    ];

    useEffect(() => {
        //! Dispatching API for Getting Announcement
        dispatch(MailActions.getMailList())
    }, []);

    return (
        <>
            <MainDatatable data={mailData} columns={columns} title={'Queries'} />

            {/* Product Modal */}
            <Dialog open={mailModal?.isOpen} PaperProps={{ sx: { maxWidth: { xs: '90vw', sm: '50vw' }, minWidth: { xs: '90vw', sm: '50vw' } } }}>
                <DialogContent>
                    <Grid container sx={{ alignItems: "center" }} spacing={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ fontSize: "22px", fontWeight: "500", color: Color.black }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: "10px" }}>
                                <div>Mail</div>
                                <div onClick={() => setMailModal({ isOpen: false, data: [] })} style={{ cursor: "pointer" }}><CrossSvg /></div>
                            </div>
                        </Grid>

                        {/* Iterate over each product in productData */}
                        {/* {productModal?.productData?.map((value, index) => ( */}
                        <Grid container item xs={12} spacing={2} sx={{ marginBottom: '20px' }}>
                           

                            <Grid item xs={12} md={8}>
                                <Typography variant="h6" fontWeight="bold">Name : {mailModal?.data?.name}</Typography>
                                <Typography variant="body2" fontWeight="bold" color={Color.primary}>E-Mail: {mailModal?.data?.email}</Typography>
                                <Typography variant="body2">Subject: {mailModal?.data?.subject}</Typography>


                            </Grid>

                            <Grid item xs={12} style={{ marginTop: '10px' }}>
                                <Typography variant="h6" style={{fontSize:14}}>Message: {mailModal?.data?.message}</Typography>
                            </Grid>

                           

                            {/* {productModal?.productData?.length - 1 && (<Grid item xs={12}><Divider /></Grid>)} */}
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>

        </ >
    );
}

export default MailList;