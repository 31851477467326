import { put, takeLatest } from "redux-saga/effects";
import * as actionsTypes from '../action-types';
import axios from "axios";
import { api_url, get_mail_histroy } from "../../utils/api-routes";

function* getMailList(actions) {
    try {
        const response = yield axios.get(api_url + get_mail_histroy);
        console.log('Response ::: ',response?.data)
        if(response?.data?.success) {
            yield put({ type: actionsTypes.SET_MAIL_LIST, payload: response.data.data});
        }

    } catch(e) {
        console.log(e);
    }
}

export default function* mailSaga() {
   yield takeLatest(actionsTypes.GET_MAIL_LIST,getMailList);
}